<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loader">
              <b-row>
                <b-col lg="8" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(storeUserRole)" @reset.prevent="reset">
                      <ValidationProvider name="Organization" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                        <template v-slot:label>
                          {{ $t('user_role.organization')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-select
                            plain
                            v-model="role.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authOrgId > 0"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Designation" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="designation_id"
                          slot-scope="{ valid, errors }"
                          >
                        <template v-slot:label>
                          {{ $t('user_role.designation')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-select
                            plain
                            v-model="role.designation_id"
                            :options="designationList"
                            id="designation_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Role Name (En)" vid="role_name" rules="required|min:3">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="role_name"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('user_role.role_name')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-input
                            id="role_name"
                            v-model="role.role_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Role Name (Bn)" vid="role_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="role_name_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('user_role.role_name_bn')}}<span class="text-danger">*</span>
                        </template>
                          <b-form-input
                            id="role_name_bn"
                            v-model="role.role_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { roleStoreApi, roleUpdateApi } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loader: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      role: {
        id: 0,
        role_name: '',
        role_name_bn: '',
        org_id: 0,
        designation_id: 0
      },
      designationList: [],
      authOrgId: 0
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  created () {
    if (this.id) {
      this.role = this.getRoleData()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.role = Object.assign({}, this.role, {
        org_id: this.authOrgId
      })
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.role = Object.assign({}, this.role, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.role = Object.assign({}, this.role, {
        org_id: this.authUser.office_detail.org_id
      })
    }
  },
  watch: {
    'role.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getDesignationList(newVal)
      }
    },
    'role.designation_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.setRoleNames(newVal)
      }
    }
  },
  methods: {
    getRoleData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async storeUserRole () {
      this.loader = true
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.role.id) {
        result = await RestApi.putData(authServiceBaseUrl, `${roleUpdateApi}/${this.id}`, this.role)
      } else {
        result = await RestApi.postData(authServiceBaseUrl, roleStoreApi, this.role)
      }
      if (result.success && this.role.id) {
        this.$store.dispatch('UserManagement/updateRole', this.role)
      } else if (result.success && !this.role.id) {
        loadinState.listReload = true
      }
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', {
          listReload: true
        })
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.loader = false
    },
    getDesignationList (orgId = null) {
      const designationList = this.$store.state.commonObj.designationList
      this.designationList = []
      if (orgId) {
        this.designationList = designationList.filter(designation => designation.org_id === orgId)
      }
    },
    setRoleNames (designationId) {
      if (designationId && this.role.id === 0) {
        this.role = Object.assign({}, this.role, { role_name: '', role_name_bn: '' })
        const designation = this.designationList.find(item => item.value === designationId)
        this.role = Object.assign({}, this.role, { role_name: designation.text_en, role_name_bn: designation.text_bn })
      }
    }
  }
}
</script>
