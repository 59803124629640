<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('user_role.roles') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
           <b-col xs="12" sm="12" md="5">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="org_id"
                    label-class="text-left"
                    >
                    <template v-slot:label>
                    {{ $t('user_role.organization') }} &nbsp; &nbsp;
                    </template>
                    <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="orgList"
                    :disabled="authOrgId > 0"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="5">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="role_id"
                    label-class="text-left"
                    >
                    <template v-slot:label>
                    {{ $t('user_role.roles') }} &nbsp; &nbsp;
                    </template>
                    <b-form-select
                    plain
                    v-model="search.role_id"
                    :options="roleList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="2">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user_role.roles') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(role_name)="data">
                      {{ data.item.role_name }}
                    </template>
                    <template v-slot:cell(role_name_bn)="data">
                      {{ data.item.role_name_bn }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(designation_id)="data">
                      {{ getDesigName(data.item.designation_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <!-- <b-button @click="remove(data.item)"><i class="ri-delete-bin-line"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_status" title="Click to Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="testId" :key="testId" />
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { roleListApi, roleToggleStatusApi } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  components: {
    Form
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        role_id: 0,
        org_id: 0
      },
      roles: [],
      testId: 0,
      rows: [],
      listData: [],
      authOrgId: 0
    }
  },
  computed: {
    roleList: function () {
      if (this.roles) {
        const listObject = this.roles
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.role_name_bn }
          } else {
              return { value: obj.id, text: obj.role_name }
          }
        })
        return tmpList
      } else {
        return []
      }
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('user_role.role_entry') : this.$t('user_role.role_modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('user_role.role_namel'), class: 'text-left' },
          { label: this.$t('user_role.organization'), class: 'text-left' },
          { label: this.$t('user_role.designation'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'role_name_bn' },
                { key: 'org_id' },
                { key: 'designation_id' },
                { key: 'status' },
                { key: 'action' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'role_name' },
                { key: 'org_id' },
                { key: 'designation_id' },
                { key: 'status' },
                { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.roles = this.roleDataCome(newVal)
    },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        role_id: this.authUser.office_detail.role_id
      })
    }
    this.loadData()
  },
  methods: {
    roleDataCome: function (orgId) {
      RestApi.getData(authServiceBaseUrl, 'role/role-list-select/' + orgId, {}).then(response => {
        this.roles = response
      }, error => {
        if (error) {
          //
        }
      })
    },
    getOrgName (orgId) {
      return this.$store.state.orgList.find(item => item.value === orgId).text
    },
    getDesigName (desigId) {
      if (desigId) {
        const desigList = this.$store.state.commonObj.designationList
        const desig = desigList.find(item => item.value === desigId)
        if (this.$i18n.locale === 'bn') {
          return typeof desig !== 'undefined' ? desig.text_bn : ''
        } else {
          return typeof desig !== 'undefined' ? desig.text_en : ''
        }
      }
    },
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = data.per_page * data.current_page - data.per_page + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          role_id: this.authUser.office_detail.role_id
        })
      }
      this.loadData()
    },
    edit (item) {
        this.testId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(authServiceBaseUrl, `${roleToggleStatusApi}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('UserManagement/toggleUserRoleStatus', item)
          this.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(authServiceBaseUrl, roleListApi, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', response.data.data)
                this.listData = response.data.data
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    }
  }
}
</script>
